<template>
    <div>
        <section class="section section-lg">
            <div class="container">
                <div class="row row-grid justify-content-center">
                    <div class="col-lg-12 text-left">
                        <h3 class="display-4">
                            прославляли Бога, играя на трубах, кимвалах и разных музыкальных орудиях; I-е Пар. 16:42
                        </h3>
                        <div class="home-img">
                            <b-img 
                                src="https://windorchestra.net/images/sax-blackwhite.jpg" 
                                fluid 
                            />
                        </div>
                        <h3 class="display-4">
                            Благо есть славить Господа. - Пс. 91:2
                        </h3>
                        <p>
                            Мир Вам, дорогие друзья! Приветствуем Вас со страниц нашего сайта. 
                            Особенный сердечный привет и большая благодарность всем тем, кто 
                            связал свою жизнь с духовыми оркестрами. Прославление Бога одно из 
                            величайших дел на земле. - Пс. 150.
                        </p>
                        <p>
                            Наша работа направлена на то, чтобы помочь вам в этом служении. Мы всегда рады успехам в вашем труде. 
                            Да благославит Вас Бог. Молитесь о расширении служения духовых оркестров и о создании новых коллективов. 
                            Будем рады иметь с вами контакты по этим вопросам.
                        </p>
                        <p>Слава Иисусу Христу!</p>
                        <p class="text-left">
                            К Филипп. 3:7<br>
                            бр. Александр Дарануца<br>
                            Юрий Бойко
                        </p>  
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>

export default {
    name: "Home"
};

</script>

<style scoped>
    .home-img {
        margin-top: 30px;
        margin-bottom: 30px;
    }
</style>
